import React from 'react'
import { styled } from '@linaria/react'
import { graphql, useStaticQuery } from 'gatsby'
import { AuthorCard } from '@/blocks/AuthorCard'
import { TitleMain, Wrapper } from '@/UI'
import { media } from '@/lib/theme'

interface Props {}

export const TeamAll = ({}: Props) => {
  const data = useStaticQuery<Queries.allContentfulAuthorQueryQuery>(graphql`
    query allContentfulAuthorQuery {
      allContentfulAuthor(sort: { order: ASC }, filter: { includeInLeadership: { eq: true } }) {
        nodes {
          id
          name
          slug
          bookACall
          linkedIn
          role
          bio {
            bio
            childMarkdownRemark {
              html
            }
          }
          avatar {
            gatsbyImageData(width: 392, layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP], quality: 50)
          }
        }
      }
    }
  `)

  const authors = data.allContentfulAuthor.nodes

  return (
    <TeamAllView>
      <TitleMain>Meet our founders</TitleMain>
      <AuthorsGrid>
        {authors.map(author => (
          <Author author={author} key={author.id} />
        ))}
      </AuthorsGrid>
    </TeamAllView>
  )
}

const TeamAllView = styled(Wrapper)`
  display: grid;
  padding-top: 32px;
  padding-bottom: 32px;
  grid-row-gap: 16px;

  ${media.mobileModern} {
    padding-top: 48px;
    padding-bottom: 48px;
    grid-row-gap: 24px;
  }

  ${media.desktopSmall} {
    grid-row-gap: var(--content-blocks-gap);
    padding-top: var(--content-blocks-gap);
    padding-bottom: var(--content-blocks-gap);
  }
`

const AuthorsGrid = styled.div`
  display: grid;
  grid-row-gap: 16px;
  justify-content: center;

  ${media.mobileModern} {
    grid-row-gap: 24px;
  }

  ${media.desktopSmall} {
    justify-content: unset;
  }
`

const Author = styled(AuthorCard)`
  ${media.desktopSmall} {
    max-width: 968px;
  }
`

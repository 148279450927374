import React from 'react'
import { Posts, Tags } from './components'
import { useDispatchActiveTagState } from '@/hooks'
import { useGetTags } from '~/features/insights/blog/useGetTags'
import { SimpleContentContainer } from '@/UI'

export const Blog = () => {
  const { activeTag } = useDispatchActiveTagState()
  const { tags } = useGetTags()
  const titleTag = tags.find(({ slug }) => slug === activeTag)

  return (
    <SimpleContentContainer
      title={titleTag?.tag ?? 'All articles and guides'}
      subTitleBlockContent={<Tags tags={tags} />}
      noMobilePaddingSpecial
    >
      <Posts />
    </SimpleContentContainer>
  )
}

import React, { ReactNode } from 'react'
import { styled } from '@linaria/react'
import { Styleable, WithChildren } from '@/lib/interfaces'
import { media } from '@/lib/theme'
import { Wrapper } from './Wrapper'
import { Typography } from './typography-new'

interface Props extends WithChildren, Styleable {
  title: string
  subtitle?: ReactNode
  subTitleBlockContent?: ReactNode
  noMobilePaddingContent?: boolean
  noMobilePaddingSpecial?: boolean
  contentClassName?: string
}

export const SimpleContentContainer = ({
  title,
  subtitle,
  subTitleBlockContent,
  children,
  className,
  noMobilePaddingContent,
  noMobilePaddingSpecial,
  contentClassName,
}: Props) => {
  return (
    <SimpleBaseGrid className={className}>
      <TopBlock>
        <Wrapper>
          <Typography>
            <h1>{title}</h1>
            {subtitle && <p>{subtitle}</p>}
          </Typography>
        </Wrapper>
        {subTitleBlockContent && (
          <WrapperStyled $noMobilePadding={noMobilePaddingSpecial}>{subTitleBlockContent}</WrapperStyled>
        )}
      </TopBlock>
      <Outer className={contentClassName}>
        <Wrapper $noMobilePadding={noMobilePaddingContent}>{children}</Wrapper>
      </Outer>
    </SimpleBaseGrid>
  )
}

export const SimpleBaseGrid = styled.div`
  display: grid;
  grid-row-gap: 48px;

  ${media.desktopSmall} {
    grid-row-gap: 56px;
  }
`

const TopBlock = styled.div`
  display: grid;
  grid-row-gap: 32px;
`

const WrapperStyled = styled(Wrapper)`
  display: flex;
  flex-direction: column;
`

const Outer = styled.div``

import React from 'react'
import { graphql } from 'gatsby'
import { schemaOrgOrganization } from '@/utils/schema.org'
import { SEO, Layout } from '@/blocks/layout'
import { Newsletter } from '~/features/info/newsletter/Newsletter'

const pageTitle = 'Newsletter sign-up'
const pageTitleSEO = `${pageTitle} — SAMexpert`
const pageSubtitle = 'Get industry insights and updates delivered to your inbox.'
const pageDescription =
  'Subscribe to our newsletter and receive exclusive industry insights, expert updates, and curated news straight to your inbox.'

const NewsletterPage = () => {
  return (
    <Layout breadcrumbsText="Home" breadcrumbsLink="/">
      <Newsletter title={pageTitle} subtitle={pageSubtitle} />
    </Layout>
  )
}

export default NewsletterPage

export const Head = ({ data }) => {
  return (
    <SEO
      title={pageTitleSEO}
      description={pageDescription}
      image="/subscribe_seo.png"
      richresult={schemaOrgOrganization(data.site.siteMetadata)}
    />
  )
}

export const pageQuery = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
        email
      }
    }
  }
`

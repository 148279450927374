import { graphql, useStaticQuery } from 'gatsby'

export function useGetSiteMetadata() {
  const { site } = useStaticQuery<Queries.Query>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            trademark
            social {
              bluesky
            }
            siteUrl
            email
          }
        }
      }
    `,
  )
  return site.siteMetadata
}

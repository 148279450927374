import React, { useLayoutEffect, useRef } from 'react'
import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import { SimpleContentContainer } from '@/UI'
import { colors, desktopSmallMedia } from '@/lib/theme'

interface Props {
  title: string
  subtitle: string
}

export const Newsletter = ({ title, subtitle }: Props) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null)

  const adjustIframeHeight = () => {
    const iframe = iframeRef.current
    if (iframe && iframe.contentWindow) {
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document
      if (iframeDoc && iframeDoc.body) {
        iframe.style.height = `${iframeDoc.body.scrollHeight}px` // Устанавливаем высоту
      }
    }
  }

  useLayoutEffect(() => {
    const iframe = iframeRef.current

    if (iframe) {
      iframe.onload = () => {
        adjustIframeHeight() // Устанавливаем высоту при загрузке содержимого
      }
    }

    return () => {
      if (iframe) {
        iframe.onload = null
      }
    }
  }, [])
  return (
    <SimpleContentContainer
      title={title}
      subtitle={subtitle}
      contentClassName={ContentOuterStyles}
      noMobilePaddingContent
    >
      <iframe ref={iframeRef} src="/zoho.html" title={title} style={{ width: '100%', minHeight: '1090px' }} />
    </SimpleContentContainer>
  )
}

const ContentOuterStyles = css`
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: ${colors.background.grayLightest};

  ${desktopSmallMedia} {
    padding-top: 64px;
    padding-bottom: 64px;
  }
`

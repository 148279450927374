import React from 'react'
import { Link } from 'gatsby'
import { AllLinksWrapper, SingleLinkContent } from './AllLinksWrapper'
import { ExternalLinkTemplate } from './ExternalLinkTemplate'

export const Resources = () => {
  return (
    <AllLinksWrapper gridArea="resources">
      <h2>Resources</h2>
      <ExternalLinkTemplate href="https://www.linkedin.com/company/samexpert/events/" text="Events" />
      <Link to="/newsletter">
        <SingleLinkContent>Newsletter</SingleLinkContent>
      </Link>
    </AllLinksWrapper>
  )
}
